<template>
  <div class="canxuanCommodityDistribution">
      <div class="search-features">
        <van-search v-model="titleName" placeholder="搜索全部商品!"  @search.self="onSearch" left-icon right-icon="search"/>
      </div>
      <div class="search-features-2">
        
      </div>
      <van-dropdown-menu >
        <van-dropdown-item v-model="sidx" :options="sidxs"/>
        <van-dropdown-item v-model="order" :options="orders"/>
      </van-dropdown-menu>

      <van-tabs  @click-tab.self="onClickTab">
        <van-tab title="全部">
        </van-tab>
        <van-tab v-for="obj in categoryList" :title="obj.category" :key="obj.id">
        </van-tab>
      </van-tabs>

        <van-list
          v-model:loading="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="onLoad()"
        >
          <div class="div-card" v-for="item,key in productList" :key="key">
              <van-card 
                        :price="(item.price/100).toFixed(2)" 
                        :desc="item.cmmCname" 
                        :title="item.title" 
                        :thumb-link="item.detailUrl" 
                        :thumb="item.cover" 
                        :origin-price="(item.originPrice/100).toFixed(2)"
                        :centered="true"
                        v-on:click="detail(item.productId)"
                        >
                <template #num>
                  销量{{item.sales>10000?(item.sales/10000).toFixed(2)+'W':item.sales}}
                </template>
                <template #tags>
                  <van-tag plain type="primary">佣金率{{item.activityCosRatio}}%</van-tag>
                  &nbsp;
                  <van-tag plain type="primary" v-if="item.cmmCname!=category">{{item.cmmCname}}</van-tag>
                </template>
              </van-card>
          </div>
    </van-list>
  </div>
</template>
<script>

export default {
  name: 'CanxuanCommodityDistribution',
  data(){
    return{
      account:'1',
      password:'2',
      productList:[],
      loading:false,
      finished:false,
      page:1,
      limit:50,
      titleName:'',
      categoryList:[],
      category:'',
      finishedText:'热门爆品榜单没有更多了',
      sortCol:1,
      sortType:0,
      sidxs : [
        { text: '排序类型', value: ''},
        { text: '价格', value: 'price' },
        { text: '销量', value: 'sales' },
        { text: '佣金率', value: 'activity_cos_ratio' },
      ],
      orders : [
        { text: '排序方式', value: '' },
        { text: '降序', value: 'desc' },
        { text: '升序', value: 'asc' },
      ],
      order:'desc',
      sidx:'activity_cos_ratio',
    }
  },
  watch:{
    'order'(newValue, oldValue){
          this.productList = [];
          this.page = 1;
          this.finished = false;
          this.loading = true;
          this.onLoad();
      },
      'sidx'(newValue, oldValue){
          this.productList = [];
          this.page = 1;
          this.finished = false;
          this.loading = true;
          this.onLoad();
      },
  },
  created(){
    let titleName = this.$route.query.titleName;
    let category = this.$route.query.category;
    
    if(titleName!='' && titleName!=null && titleName!=undefined){
        this.titleName = titleName;
    }
    if(category!='' && category!=null && category!=undefined){
        this.category = category;
    }
    this.initCategory();
  },
  methods:{
    detail(goodsId){
      this.$router.push({
        name:'CmmJxGoodsDetails',
        query: {goodsId:goodsId}
      })
    },
    dropdownSidxItemChange(){
      console.log(this.sidx);
      console.log(this.order);
    },
    onSearch(){
      console.log("onSearch");
      if(this.titleName != ''){
        this.productList = [];
        this.page = 1;
        this.finished = false;
        this.loading = true;
        this.onLoad();
      }
    },
    onClickTab({ title }){
      console.log("onClickTab");
      if(title == '全部'){
        title = '';
      }
      if(this.category != title){
        this.category = title;
        this.productList = [];
        this.page = 1;
        this.finished = false;
        this.loading = true;
        this.onLoad();
      }
    },
    initCategory(){
      // 获取类目
      this.axios.post('/app/canmama/categoryQry', {
            }).then((response) => {
              this.categoryList = response;
            }).catch(function (error) {
                console.log(error);
            });
    },
    onLoad(){
      // 异步更新数据
      this.axios.post('/app/canmama/getGoodsPage', {
                page:this.page,
                sidx:this.sidx,
                order:this.order,
                title:this.titleName,
                category:this.category,
                limit:this.limit
              }).then((response) => {
              console.log(response);
              // 数据全部加载完成
              if (response.totalCount<this.limit) {
                this.finished = true;
              }
              this.productList = [...this.productList,...response.list];
              // 加载状态结束
              this.loading = false;
              this.page++;
              if(this.productList.length == 0){
                this.finishedText = this.category + '没有更多了!';
              }
            }).catch(function (error) {
                console.log(error);
                // 加载状态结束
                this.loading = false;
            });
            
    }
  }
}
</script>
<style>
  .search-features {
    position: fixed !important;
    z-index: 99 !important;
    width:100% !important;
  }
  .search-features-2 {
    height: 45px;
  }
  .div-card {
    padding: 1px 0;
  }
  .canxuanCommodityDistribution {
    /* background-color: #f9f9f9; */
    --van-card-background-color	:#fff;
  }
  .host-van-grid {
    --van-grid-item-text-color:#f1e6e6;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
</style>
